<template>
  <div>
    <head-layout  :head-title="'数据库采集'"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>
    <el-container class="new-sino-box">
      <el-header class="new-sino-header">
        <div >
          <el-input placeholder="请输入接口名称" v-model="queryList.param1" class="new-sino-input" size="mini" />
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </div>
<!--        <div>-->
<!--          <el-button size="mini" type="primary" @click="addFn">新增</el-button>-->
<!--          <el-button size="mini" type="danger" @click="deleteFn">删除</el-button>-->
<!--        </div>-->

      </el-header>
      <el-main class="new-sino-main">
        <el-table
          v-tableHe
          size="mini"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="infCode"
            label="接口编码"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="infName"
            label="接口名称"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="dataTable"
            label="表名">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tsField"
            label="增量字段">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tsFieldType"
            label="增量时间类型">
          </el-table-column>
          <el-table-column
            align="center"
            prop="formatType"
            label="增量时间类型格式化">
          </el-table-column>
          <el-table-column
            align="center"
            prop="dataSource"
            label="数据源">
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="float:right;height: 60px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.query.current"
            :page-size="queryList.query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  gatherlist,
  gatherUp,
  gatherdel,
  datSoSel,
  getAutoCode, totalCollectDel
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      multipleSelection:[],
      tableData: [],
      selDat:[],
      total:0,
      queryList:{
        query:{
          current:1,
          size:10,
        },
        param1:''
      },
      loading:false,
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      gatherlist(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.records
        this.total = obj.total
      });
    },

    addFn(){
      this.$router.push({
        path: '/dataAcquisition/sjcjAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(index,item){
      this.$router.push({
        path: '/dataAcquisition/sjcjAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        gatherdel(ids).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });
    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.size = val;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.queryList.page = val;
      this.onLoad()
    },
  },
  created() {
    this.onLoad();
  },
  mounted() {
  },
};
</script>

<style>
.new-sino-box{
  background: #fff;
  padding:10px;
}
.new-sino-header{
  height: 39px!important;
  background: rgb(243, 243, 243);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-input__inner{
  margin-right: 5px;
}
.new-sino-input{
  width: 200px;
  margin-right: 10px;
}
.new-form-input{
  width: 300px;
}
.new-form-input .el-input{
  width: 300px;
}
.el-button+.el-button {
  margin-left: 0px;
}
</style>
